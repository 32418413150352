const CommentsList = ({ comments = [] }) => {
  return (
    <div className="posts__lists grid gap3">
      {comments.map((post) => (
        <div
          key={post.id}
          className="post__card p4 radius2 flex flex-col gap1"
          data-aos="fade-up"
        >
          <h2>{post.name}</h2>
          <p>{post.body}</p>
        </div>
      ))}
    </div>
  );
};

export default CommentsList;
