import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const globalAPIService = createApi({
  reducerPath: "globalAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://jsonplaceholder.typicode.com/",
  }),
  tagTypes: ["Global"],
  endpoints: (builder) => ({
    getPosts: builder.query({ query: () => "posts" }),
    getComments: builder.query({ query: () => "comments" }),
    getAlbums: builder.query({ query: () => "albums" }),
    getPhotos: builder.query({ query: () => "photos" }),
    getTodos: builder.query({ query: () => "todos" }),
    getUsers: builder.query({ query: () => "users" }),
  }),
});

export const {
  useGetPostsQuery,
  useGetCommentsQuery,
  useGetAlbumsQuery,
  useGetPhotosQuery,
  useGetTodosQuery,
  useGetUsersQuery,
} = globalAPIService;
