import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.scss";
import App from "./App";
import Home from "./pages/home";
import Courses from "./pages/courses";
import PageNotFound from "./pages/page-not-found";
import Login from "./pages/login";
import {
  LOGIN_ROUTE,
  HOME_ROUTE,
  COURSES_ROUTE,
  PROFILE_ROUTE,
} from "./utils/routes";
import Profile from "./pages/profile";
import { Provider } from "react-redux";
import store from "./redux/store";

const router = createBrowserRouter([
  {
    path: LOGIN_ROUTE,
    element: <Login />,
  },
  {
    path: HOME_ROUTE,
    element: <App />,
    children: [
      { path: "", element: <Home /> },
      { path: COURSES_ROUTE, element: <Courses /> },
      { path: PROFILE_ROUTE, element: <Profile /> },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
