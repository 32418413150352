import { NavLink } from "react-router-dom";
import { menuItems } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/auth-slice";
const Drawer = () => {
  const dispatch = useDispatch();

  return (
    <div className="side__menu">
      <div className="side__menu__list">
        {menuItems.map((menu, i) => (
          <NavLink key={i} to={menu.path}>
            {menu.title}
          </NavLink>
        ))}
      </div>
      <button onClick={() => dispatch(logout())}>Logout</button>
    </div>
  );
};
export default Drawer;
