import CommentsList from "../components/comments-list";
import Loader from "../components/loader";
import { useGetCommentsQuery } from "../services/global-service";

const Home = () => {
  const { isLoading, data, error } = useGetCommentsQuery();
  if (isLoading) return <Loader />;
  if (error) return <div>error</div>;
  return (
    <div className="body__container">
      <CommentsList comments={data} />
    </div>
  );
};
export default Home;
