import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/auth-slice";
import { Navigate, useLocation } from "react-router-dom";
import { isAuthenticatedReducer } from "../redux/reducers";

const Login = () => {
  const isAuthenticated = useSelector(isAuthenticatedReducer);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ username, password }));
  };

  if (isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <div className="login p4 flex align-center justify-center">
      <div className="login__container p8 flex flex-col justify-center gap3">
        <h2>Login</h2>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-center gap3"
        >
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
