import { configureStore } from "@reduxjs/toolkit";
import globalSlice from "./global-slice";
import authSlice from "./auth-slice";
import { globalAPIService } from "../services/global-service";

const store = configureStore({
  reducer: {
    global: globalSlice,
    auth: authSlice,
    [globalAPIService.reducerPath]: globalAPIService.reducer,
  },
  middleware: (middleware) =>
    middleware({ serializableCheck: false }).concat(
      globalAPIService.middleware
    ),
});

export default store;
