import { useGetUsersQuery } from "../services/global-service";
import Loader from "../components/loader";
import UsersList from "../components/users-list";

const Profile = () => {
  const { isLoading, data, error } = useGetUsersQuery();
  if (isLoading) return <Loader />;
  if (error) return <div>error</div>;
  return (
    <div className="body__container">
      <UsersList users={data} />
    </div>
  );
};
export default Profile;
