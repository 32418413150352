import { useSelector } from "react-redux";
import Drawer from "./components/app/drawer";
import { isAuthenticatedReducer } from "./redux/reducers";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LOGIN_ROUTE } from "./utils/routes";

const App = () => {
  const isAuthenticated = useSelector(isAuthenticatedReducer);
  const location = useLocation(); // Get the current route

  if (!isAuthenticated && location.pathname !== LOGIN_ROUTE) {
    return <Navigate to={LOGIN_ROUTE} state={{ from: location }} replace />;
  }

  return (
    <div className="app">
      <Drawer />
      <section>
        <Outlet />
      </section>
    </div>
  );
};

export default App;
